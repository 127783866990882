import axios from "axios"
import { Message } from "element-ui";  // 引入

/**
 * 请求失败后的错误统一处理
 * @param status {number | string} 请求失败的状态码
 * @param other {string} 错误提示信息
 */
const handleError = (status, other) => {

  // 状态码判断
  switch (status) {
    // 原生网络请求错误
    case "ERR_NETWORK":
      Message.error("网络异常")
      break
    case "ECONNABORTED":
      Message.error("请求超时")
      break
    // 自定义的错误
    case 400:
      Message.error(other || "请求数据错误")
      break
    case 404:
      Message.error("网络请求不存在")
      break
    case 500:
      Message.error(other || "服务器发生错误，请检查服务器")
      break
    default:
      Message.error(other)
  }
}

/* 实例化请求配置 */
const service = axios.create({
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  },
  timeout: 1000 * 20,
  baseURL: 'https://signadmin.jiaxinzp.com/api',
  // baseURL: 'http://192.168.0.108:2000/api',
  // 表示跨域请求时是否需要使用凭证
  withCredentials: false
})

/**
 * 请求拦截器
 * 每次请求前携带token
 */
service.interceptors.request.use(
  (req) => {
    if (req.method === "get") {
      req.url = req.url + "?t=" + new Date().getTime()
    }
    return req
  },
  (error) => {
    Message.error("请求失败")
    return Promise.reject(new Error(error.toString()))
  }
)

/**
 * 响应拦截器
 * */
service.interceptors.response.use(
  (res) => {
    // 下载文件
    if (res.request.responseType === "blob" && res.status === 200 && res.data) {
      return Promise.resolve(res.data)
    }
    if (res.status === 200 || res.status === 204) {
      const { code, msg } = res.data
      if (code === 200) {
        return Promise.resolve(res.data)
      }
      handleError(code, msg)
      return Promise.reject(new Error(res.toString()))
    } else {
      handleError("", JSON.stringify(res || ""))
      return Promise.reject(new Error(res.toString()))
    }
  },
  (error) => {
    const { response } = error
    if (!(response && response.data)) {
      handleError(error.code, error.message)
      return Promise.reject(new Error(error.toString()))
    }
    const data = response.data
    if (data.code) {
      handleError(data.code, data.msg)
    } else {
      handleError("", data.error)
    }
    return Promise.reject(new Error(error.toString()))
  }
)

export default service
